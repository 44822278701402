import React from "react";
import ListProductsItem from "./ListProductsItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Banner from "./Banner";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

const ListProductsSlider = (props) => {
  const { data, bannerImage, title, link = "/" } = props || [];

  return (
    <>
      {/* {bannerImage && <Banner bannerImage={bannerImage} />} */}
      <div className="title-link-wrapper after-none appear-animate mb-2 fadeIn appear-animation-visible">
        <h2 className="title title-special-block-custom pr-4">{title}</h2>
        <Link
          to={link}
          className="btn btn-link btn-icon-right btn-show-all-special-custom"
        >
          Pogledajte našu ponudu
          <FontAwesomeIcon icon={faArrowRight} />
        </Link>
      </div>
      <div className="swiper-container swiper-theme mb-10 pb-1 appear-animate swiper-custom-list-items">
        <div className="row product-wrapper show-code-action">
          <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={3}
            navigation
            loop={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            breakpoints={{
              100: {
                slidesPerView: 1,
              },
              510: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {data?.map((item, key) => (
              <SwiperSlide key={key}>
                <ListProductsItem item={item} key={key} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default ListProductsSlider;
