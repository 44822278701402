import React, { useEffect } from "react";
import IntroHome from "../components/IntroHome";
import AsideHome from "../components/AsideHome";
import ListProducts from "../components/ListProducts";
import ListProductsSlider from "../components/ListProductsSlider";
import { useDispatch } from "react-redux";
import {
  fetchCilentsList,
  fetchProductsCategoriesTypeAll,
  fetchSlidersList,
  fetchBannersList,
  fetchProductsHomeCategoryAll1,
  fetchProductsHomeCategoryAll2,
} from "../actions/global";
import { useSelector } from "react-redux";
import ListProductsVertical from "../components/ListProductsVertical";
import Banner from "../components/Banner";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import HelmetSeo from "../components/Reusable/HelmetSeo";

const Home = () => {
  const dispatch = useDispatch();

  const {
    dataProductsMesecnaAkcijaList,
    dataProductsPreporucujemoList,
    dataProductsIzdvajamoIzPonudeList,
    dataProductsSpecijalnaAkcijaList,
    dataProductsList1,
    dataProductsList2,
    dataClientListAll,
    dataBannerListAll,
  } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    const type1 = "mesecnaAkcija";
    const type2 = "popularno";
    const type3 = "izdvajamo";
    const type4 = "preporucujemo";
    const type5 = "specijalnaAkcija";
    const type6 = "ponovoNaStanju";

    const dataValue = {
      tip: "grupa",
      grupa: "TELEVIZORI",
      limit: 6,
    };
    dispatch(fetchProductsHomeCategoryAll1(dataValue));

    const dataValue2 = {
      tip: "grupa",
      grupa: "Laptopovi",
      limit: 6,
    };
    dispatch(fetchProductsHomeCategoryAll2(dataValue2));

    dispatch(fetchProductsCategoriesTypeAll(type1));
    dispatch(fetchProductsCategoriesTypeAll(type2));
    dispatch(fetchProductsCategoriesTypeAll(type3));
    dispatch(fetchProductsCategoriesTypeAll(type4));
    dispatch(fetchProductsCategoriesTypeAll(type5));
    dispatch(fetchProductsCategoriesTypeAll(type6));

    dispatch(fetchCilentsList());
    dispatch(fetchSlidersList());
    dispatch(fetchBannersList());
  }, [dispatch]);

  const bannerPosition2 = dataBannerListAll?.filter(
    (item) => item.anPosition == 2
  );
  const bannerPosition3 = dataBannerListAll?.filter(
    (item) => item.anPosition == 3
  );
  const bannerPosition4 = dataBannerListAll?.filter(
    (item) => item.anPosition == 4
  );
  const bannerPosition5 = dataBannerListAll?.filter(
    (item) => item.anPosition == 5
  );
  const bannerPosition6 = dataBannerListAll?.filter(
    (item) => item.anPosition == 6
  );
  const bannerPosition7 = dataBannerListAll?.filter(
    (item) => item.anPosition == 7
  );

  return (
    <>
      <HelmetSeo
        title={`Početna strana | UP-IT Shop`}
        description={``}
        keywords={``}
      />
      <IntroHome
        bannerPosition6={bannerPosition6}
        bannerPosition7={bannerPosition7}
      />
      <div className="row gutter-md">
        <AsideHome />
        <div className="main-content">
          <div className="brand-wrapper mb-7 appear-animate br-sm">
            <div className="swiper-container swiper-theme swiper-wrapper-logos">
              <Swiper
                loop={true}
                modules={[Navigation, Autoplay]}
                spaceBetween={2}
                slidesPerView={6}
                // navigation
                autoplay={{
                  delay: 4500,
                  disableOnInteraction: false,
                }}
                scrollbar={{ draggable: true }}
                breakpoints={{
                  100: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                  },
                  510: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                  1024: {
                    slidesPerView: 6,
                    spaceBetween: 2,
                  },
                }}
              >
                {dataClientListAll?.map((item, key) => (
                  <SwiperSlide key={key}>
                    <div className="swiper-slide" key={key}>
                      <figure>
                        <img
                          src={item.image}
                          alt={item.acName}
                          width="252"
                          height="100"
                        />
                      </figure>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          {dataProductsIzdvajamoIzPonudeList?.length > 0 && (
            <ListProductsSlider
              data={dataProductsIzdvajamoIzPonudeList}
              title={`Izdvajamo iz ponude`}
              link="/izdvajamo-iz-ponude"
            />
          )}
          {bannerPosition2.length > 0 && <Banner item={bannerPosition2[0]} />}

          {dataProductsSpecijalnaAkcijaList?.length > 0 && (
            <ListProductsSlider
              data={dataProductsSpecijalnaAkcijaList}
              title={`Specijalna akcija`}
              link="/specijalna-akcija"
            />
          )}
          {bannerPosition3.length > 0 && <Banner item={bannerPosition3[0]} />}

          {dataProductsList2?.length > 0 && (
            <div className="homepage-list-categories-items">
              <ListProducts
                data={dataProductsList2}
                title={`Laptopovi`}
                bannerImage={`33.jpeg`}
              />
            </div>
          )}

          <div className="category-banner-wrapper row mb-7 appear-animate">
            <div className="col-md-6 mb-4">
              {bannerPosition4.length > 0 && (
                <div className="banner banner-fixed br-sm">
                  <figure>
                    <img
                      src={bannerPosition4[0].image}
                      alt={bannerPosition4[0].acTitle}
                      width="530"
                      height="182"
                      style={{ backgroundColor: "#edebec" }}
                    />
                  </figure>
                  <div className="banner-content y-50">
                    <h2 className="banner-title text-white font-weight-bold ls-normal mb-0">
                      {bannerPosition4[0].acTitle}
                    </h2>
                    <h4 className="banner-price-info text-white font-weight-bold">
                      {bannerPosition4[0].acSubtitle}
                    </h4>
                    <Link
                      to={bannerPosition4[0].acLink}
                      className="btn btn-sm btn-white btn-outline btn-rounded slide-animate"
                    >
                      {bannerPosition4[0].acButton}
                    </Link>
                  </div>
                </div>
              )}
            </div>
            <div className="col-md-6 mb-4">
              {bannerPosition5.length > 0 && (
                <div className="banner banner-fixed br-sm">
                  <figure>
                    <img
                      src={bannerPosition5[0].image}
                      alt={bannerPosition5[0].acTitle}
                      width="530"
                      height="182"
                      style={{ backgroundColor: "#edebec" }}
                    />
                  </figure>
                  <div className="banner-content y-50">
                    <h2 className="banner-title text-white font-weight-bold ls-normal mb-0">
                      {bannerPosition5[0].acTitle}
                    </h2>
                    <h4 className="banner-price-info text-white font-weight-bold">
                      {bannerPosition5[0].acSubtitle}
                    </h4>
                    <Link
                      to={bannerPosition5[0].acLink}
                      className="btn btn-sm btn-white btn-outline btn-rounded slide-animate"
                    >
                      {bannerPosition5[0].acButton}
                    </Link>
                  </div>
                </div>
              )}
            </div>
          </div>
          {dataProductsList1?.length > 0 && (
            <div className="homepage-list-categories-items">
              <ListProducts
                data={dataProductsList1}
                title={`Televizori`}
                bannerImage={`33.jpeg`}
              />
            </div>
          )}
          <div className="row cols-md-3 cols-sm-2 cols-1 appear-animate sections-category-list mb-5">
            <div className="products-widget-wrapper">
              <ListProductsVertical
                data={dataProductsIzdvajamoIzPonudeList}
                title={`Izdvajamo iz ponude`}
                limit={3}
              />
            </div>
            <div className="products-widget-wrapper">
              <ListProductsVertical
                data={dataProductsMesecnaAkcijaList}
                title={`Mesečna akcija`}
                limit={3}
              />
            </div>
            <div className="products-widget-wrapper">
              <ListProductsVertical
                data={dataProductsPreporucujemoList}
                title={`Preporučujemo`}
                limit={3}
              />
            </div>
          </div>
        </div>
        {/* <!-- End of Main-content --> */}
      </div>
      {/* <!-- End of Row --> */}
    </>
  );
};

export default Home;
