import React from "react";
import { Link } from "react-router-dom";

const CategoryBox = ({ item }) => {
  return (
    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 mb-4">
      <div className="category category-group-image br-sm">
        <div className="category-content">
          <h4 className="category-name category-name-custom-page">
            <Link
              to={`/katalog/nadgrupa/${
                item.nadgrupa ? item.nadgrupa : "OSTALO"
              }?page=1`}
            >
              {item.nadgrupa ? item.nadgrupa : "OSTALO"}
            </Link>
          </h4>
          <ul className="category-list">
            {item.grupe?.map((item2, key) => (
              <li key={key}>
                <Link to={`/katalog/grupa/${item2}?page=1`}>{item2}</Link>
              </li>
            ))}
          </ul>
        </div>
        {/* <a >
          <figure className="category-media">
            <img
              src="/images/2-4.jpg"
              alt="Category"
              width="600"
              height="675"
            />
          </figure>
        </a> */}
      </div>
    </div>
  );
};

export default CategoryBox;
