import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ListProductsItem from "../components/ListProductsItem";
import { fetchProductsCategoriesTypeAll } from "../actions/global";
import HelmetSeo from "../components/Reusable/HelmetSeo";

const MesecnaAkcija = () => {
  const dispatch = useDispatch();

  const { dataProductsMesecnaAkcijaList } = useSelector(
    (state) => state.globalReducer
  );

  useEffect(() => {
    const type1 = "mesecnaAkcija";

    dispatch(fetchProductsCategoriesTypeAll(type1));
  }, [dispatch]);

  //prvo ucitavanje
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);
  return (
    <>
      <HelmetSeo
        title={`Mesečna akcija | UP-IT Shop`}
        description={``}
        keywords={``}
      />
      <nav className="breadcrumb-nav">
        <div className="container1">
          <ul className="breadcrumb bb-no">
            <li>
              <Link to="/">Pocetna -</Link>
            </li>
            <li>
              <a>Akcija -</a>
            </li>
            <li>Mesečna akcija</li>
          </ul>
        </div>
      </nav>
      <div className="page-content">
        <div className="container1">
          <div className="shop-content row gutter-lg mb-10">
            <div className="main-content1">
              <nav className="toolbox sticky-toolbox sticky-content fix-top">
                <div className="toolbox-left">
                  <div className="serach-box-item-content-text">
                    Mesečna akcija
                    <span>
                      ({dataProductsMesecnaAkcijaList.length} proizvoda)
                    </span>
                  </div>
                </div>
              </nav>
              <div className="product-wrapper row cols-lg-4 cols-md-3 cols-sm-2 cols-1">
                {dataProductsMesecnaAkcijaList.length > 0 ? (
                  <>
                    {dataProductsMesecnaAkcijaList?.map((item, key) => (
                      <div className="product-wrap" key={key}>
                        <ListProductsItem item={item} />
                      </div>
                    ))}
                  </>
                ) : (
                  <div className="col-12">
                    <div className="alert alert-icon alert-primary alert-bg alert-inline show-code-action">
                      <h4 className="alert-title">
                        <FontAwesomeIcon icon={faInfoCircle} />
                      </h4>{" "}
                      Tražene proizvode trenutno nemamo u ponudi.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MesecnaAkcija;
