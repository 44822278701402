import React from "react";
import ListProductsVerticalItem from "./ListProductsVerticalItem";

const ListProductsVertical = ({ title, data, limit }) => {
  return (
    <>
      <div className="title-link-wrapper mb-3 mt-0">
        <h4 className="title title-link title-sm font-weight-bold pt-0 pb-1">
          {title}
        </h4>
      </div>
      <div className="widget widget-products">
        {data?.slice(0, limit).map((item, key) => (
          <ListProductsVerticalItem item={item} key={key} />
        ))}
      </div>
    </>
  );
};

export default ListProductsVertical;
