import React, { useEffect, useState } from "react";
import ListProductsItem from "../components/ListProductsItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faListUl } from "@fortawesome/free-solid-svg-icons";
import Banner from "../components/Banner";
import HelmetSeo from "../components/Reusable/HelmetSeo";
import { useLocation, useParams, useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Pagination, Stack } from "@mui/material";
import CatalogFiltersList from "../components/CatalogFiltersList";
import Loader from "../components/Reusable/Loader";

const Catalog = () => {
  const { key, value } = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const [numberPerPage, setNumberPerPage] = useState(15);

  const params = new URLSearchParams(location.search);

  const openSidebarFilter = () => {
    document.body.classList.add("sidebar-active");
  };
  const closeSidebarFilter = () => {
    document.body.classList.remove("sidebar-active");
  };

  // Add a second foo parameter.
  const queryParams = {};
  params.forEach((value, key) => {
    queryParams[key] = value;
  });

  const {
    dataProductsList,
    dataCatalogPaginationList,
    dataCatalogFiltersList,
    loadingCatalog,
  } = useSelector((state) => state.globalReducer);

  const [currentPage, setCurrentPage] = useState(queryParams.page);
  const [orderCatalog, setOrderCatalog] = useState(
    localStorage.getItem("orderCatalog") || "asc"
  );

  const setPageCurrent = () => {
    setCurrentPage(1);
  };

  const handleSortChange = (e) => {
    const newOrder = e.target.value;
    setOrderCatalog(newOrder); // Postavljamo novo sortiranje u stanje
    localStorage.setItem("orderCatalog", newOrder); // Sačuvajte novo sortiranje u lokalnom skladištu
  };

  const handlePageChange = (event, pageNumber) => {
    event.preventDefault();
    setCurrentPage(pageNumber.toString());

    const queryParams = new URLSearchParams(location.search);
    const params = {};

    // Prođite kroz sve parametre i sačuvajte ih u objektu params
    queryParams.forEach((value, key) => {
      // Ako ključ već postoji u params objektu, dodajte trenutnu vrednost u niz
      if (params[key]) {
        params[key].push(value);
      } else {
        // Inače, inicijalizujte niz sa trenutnom vrednošću
        params[key] = [value];
      }
    });

    // Postavite novu vrednost za page parametar
    params["page"] = pageNumber.toString();

    // Konstruišite novi URL
    const queryString = Object.keys(params)
      .map((key) =>
        // Ako vrednost za ključ nije niz, konvertujte je u niz pre mapiranja
        Array.isArray(params[key])
          ? params[key]
              .map(
                (val) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`
              )
              .join("&")
          : `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");

    const newPath = `${location.pathname}?${queryString}`;
    navigate(newPath);
  };

  useEffect(() => {
    if (queryParams.page == 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(queryParams.page);
    }
  }, [queryParams.page]);

  //prvo ucitavanje
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  }, []);

  return (
    <>
      <HelmetSeo
        title={`${
          dataProductsList.length > 0 && dataProductsList[0].nadgrupa
        } | ${
          dataProductsList.length > 0 && dataProductsList[0].grupa
        } | UP-IT Shop`}
        description={`opis`}
        keywords={`keys`}
      />
      <nav className="breadcrumb-nav">
        <div className="container1">
          <ul className="breadcrumb bb-no">
            <li>
              <Link to="/">Pocetna -</Link>
            </li>
            <li>
              <a>Katalog -</a>
            </li>
            <li>{value}</li>
          </ul>
        </div>
      </nav>
      <div className="page-content">
        <div className="container1">
          <div className="shop-content row gutter-lg mb-10">
            <aside className="sidebar shop-sidebar sticky-sidebar-wrapper sidebar-fixed">
              <div className="sidebar-overlay"></div>
              {/* <a className="sidebar-close" onClick={closeSidebarFilter}>
                <i className="close-icon"></i>
              </a> */}

              <div className="sidebar-content scrollable">
                <div className="sticky-sidebar">
                  <CatalogFiltersList
                    dataCatalogFiltersList={dataCatalogFiltersList}
                    dataProductsList={dataProductsList}
                    grupa={value}
                    setPageCurrent={setPageCurrent}
                    tip={key}
                    perPage={numberPerPage}
                    page={currentPage}
                    orderCatalog={orderCatalog}
                    closeSidebarFilter={closeSidebarFilter}
                  />
                </div>
              </div>
            </aside>
            <div className="main-content">
              <nav className="toolbox sticky-toolbox sticky-content fix-top">
                <div className="toolbox-left">
                  <a
                    className="btn btn-primary btn-outline btn-rounded left-sidebar-toggle 
                                        btn-icon-left d-block d-lg-none"
                    onClick={openSidebarFilter}
                  >
                    <FontAwesomeIcon icon={faListUl} />
                    {/* <span>Filteri</span> */}
                  </a>
                  <div className="toolbox-item toolbox-sort select-box text-dark custom-title-category">
                    <div className="serach-box-item-content-text">
                      <b>{value}</b>
                    </div>
                  </div>
                </div>
                <div className="toolbox-right">
                  <div className="toolbox-item toolbox-show select-box">
                    <label>Sortiraj po :</label>
                    <select
                      name="orderby"
                      className="form-control"
                      value={orderCatalog}
                      style={{ width: "150px" }}
                      onChange={(e) => handleSortChange(e)}
                    >
                      <option value="desc">Cena opadajuće</option>
                      <option value="asc">Cena rastuće</option>
                    </select>
                    {/* <select
                      name="count"
                      className="form-control"
                      defaultValue={15}
                      onChange={(e) => functionSetNumberPerPage(e.target.value)}
                      style={{ width: "100px", marginLeft: "10px" }}
                    >
                      <option value="9">Prikaži 9</option>
                      <option value="15">Prikaži 15</option>
                      <option value="24">Prikaži 24</option>
                      <option value="36">Prikaži 36</option>
                    </select> */}
                  </div>
                </div>
              </nav>
              <div className="row">{/* <Banner /> */}</div>
              {loadingCatalog ? (
                <Loader />
              ) : (
                <div className="product-wrapper row cols-md-3 cols-sm-2 cols-1">
                  {dataProductsList.length > 0 ? (
                    <>
                      {dataProductsList?.map((item, key) => (
                        <div className="product-wrap" key={key}>
                          <ListProductsItem item={item} />
                        </div>
                      ))}
                    </>
                  ) : (
                    <div className="col-12">
                      <div className="alert alert-icon alert-primary alert-bg alert-inline show-code-action">
                        <h4 className="alert-title">
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </h4>{" "}
                        Trenutno nemamo proizvode iz ove kategorije
                      </div>
                    </div>
                  )}
                </div>
              )}

              {dataCatalogPaginationList > 1 && (
                <div className="toolbox toolbox-pagination justify-content-center">
                  <Stack spacing={2}>
                    <Pagination
                      count={dataCatalogPaginationList}
                      page={parseInt(currentPage)}
                      onChange={handlePageChange}
                      showFirstButton
                      showLastButton
                      size="large"
                    />
                  </Stack>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Catalog;
