import React, { Fragment, useEffect } from "react";
import ThemeWrapper from "../../components/UI/ThemeWrapper";
import { useLocation } from "react-router-dom";

const SecuredRoute = ({ ...otherProps }) => {
  function ScrollToTopOnRouteChange() {
    const location = useLocation();
    const hashValue = window.location.hash.substring(1);

    useEffect(() => {
      if (!hashValue) {
        window.scrollTo({
          top: 0,
          behavior: "instant",
        });
      }
    }, [location, hashValue]);

    return null;
  }

  return (
    <Fragment>
      <ScrollToTopOnRouteChange />
      <ThemeWrapper {...otherProps}></ThemeWrapper>
    </Fragment>
  );
};

export default SecuredRoute;
